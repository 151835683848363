import { useContext, useState } from 'react';
import { Store } from '../store/Store';
import { useParams,useSearchParams } from 'react-router-dom';

import lodash from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import B2CClientConnector from '../interfaces/B2CClientConnector';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const REFRESH_DETAIL_MAX_LENGTH = 50;

const colors = require('ac-colors');
//although this is the Business2CloudConnectorConfigureCad.tsx const is still called Business2CloudConnectorManageCard

const Business2CloudConnectorManageCard = ({
  clientConnector,
  userList,
  onAssignUserClick,
  onEditClientConnectorClick,
  onDisconnectClientConnectorClick,
  onDeleteClientConnectorClick,
  onProcessClientConnectorClick,
}: {
  clientConnector: B2CClientConnector;
  userList: any[] | undefined;
  onAssignUserClick: (clientConnector: B2CClientConnector) => void;
  onEditClientConnectorClick: (clientConnector: B2CClientConnector) => void;
  onDisconnectClientConnectorClick: (clientConnector: B2CClientConnector) => Promise<any>;
  onDeleteClientConnectorClick: (clientConnector: B2CClientConnector) => Promise<any>;
  onProcessClientConnectorClick: (clientConnector: B2CClientConnector) => Promise<void>;
}) => {

  const truncateRefreshDetail = (text: string) => {
    if (!text) return 'No detail';
    return text.length > REFRESH_DETAIL_MAX_LENGTH
      ? text.slice(0, REFRESH_DETAIL_MAX_LENGTH) + '...'
      : text;
  };

  const isDetailTruncated = (text: string) => {
    return text && text.length > REFRESH_DETAIL_MAX_LENGTH;
  };

  const users =
    clientConnector.Properties.find((_: any) => _.Name === 'portal.user')
      ?.Value?.trim()
      .split(' ')
      .map((user: any) => userList?.find((_) => _.Id === user)) || [];

  const { connectorType } = useParams();
  const [searchParams] = useSearchParams();
  const store = useContext(Store).store;
  const search = searchParams.get('search') as string;
  const [showCopied, setShowCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const rawDetail = clientConnector.Log?.RefreshDetail || 'No detail';
  const truncatedDetail = truncateRefreshDetail(rawDetail);
  const truncated = isDetailTruncated(rawDetail);

  // Only show the first 100 chars after the first ": "

  // Copy to clipboard
  const onCopyRefreshDetailToClipboard = () => {
    if (clientConnector.Log?.RefreshDetail) {
      navigator.clipboard.writeText(clientConnector.Log.RefreshDetail);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 3000);
    }
  };

  const filter =
    !search ||
    clientConnector.Name.toLowerCase().indexOf(search) >= 0 ||
    users.filter(
      (user: any) =>
        !search ||
        user?.FirstName?.toLowerCase().indexOf(search) >= 0 ||
        user?.LastName?.toLowerCase().indexOf(search) >= 0 ||
        user?.EmailAddress?.toLowerCase().indexOf(search) >= 0
    ).length > 0;

  if (!filter) return <></>;

  let manuallyTriggeredAtUtcCheck = clientConnector.Log?.ManuallyTriggeredAtUtc
    ? new Date(new Date(clientConnector.Log.ManuallyTriggeredAtUtc).getTime() + 1000 * 60 * 30)
    : new Date();

  let automaticallyTriggeredAtUtcCheck = clientConnector.Log?.AutomaticallyTriggeredAtUtc
    ? new Date(new Date(clientConnector.Log.AutomaticallyTriggeredAtUtc).getTime() + 1000 * 60 * 30)
    : new Date();

  const isRefreshing = !!clientConnector.Log?.ManuallyTriggeredAtUtc && !clientConnector.Log?.CompletedAtUtc;

  let hasWarning =
    !clientConnector.Log?.CompletedAtUtc &&
    ((!!clientConnector.Log?.ManuallyTriggeredAtUtc && manuallyTriggeredAtUtcCheck < new Date()) ||
      (automaticallyTriggeredAtUtcCheck > manuallyTriggeredAtUtcCheck &&
        !!clientConnector.Log?.AutomaticallyTriggeredAtUtc &&
        automaticallyTriggeredAtUtcCheck < new Date()));

  const renderStatusIcon = () => {
    if (clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED') return <></>;

    if (hasWarning && !clientConnector.Log?.HasError)
      return <span className="material-symbols-rounded user-select-none warning">warning</span>;
    else if (!hasWarning && clientConnector.Log?.HasError)
      return <span className="material-symbols-rounded user-select-none alert">error</span>;

    if (clientConnector.Log && !clientConnector.Log?.CompletedAtUtc)
      return <span className="material-symbols-rounded user-select-none pending">schedule</span>;

    if (clientConnector.Log?.CompletedAtUtc)
      return <span className="material-symbols-rounded user-select-none active">check_circle</span>;

    return <span className="material-symbols-rounded user-select-none pending">pending</span>;
  };

  const renderStatusText = () => {
    if (clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED') return <></>;

    if (clientConnector.Log?.Forbidden) {
      return (
        <div className="d-flex flex-column">
          <small className="text-warning">
            <b>This connector may have been disconnected. Please re-authorise or disconnect fully.</b>
          </small>
          {clientConnector.Log?.CompletedAtUtc && (
            <small className="text-muted">
              Completed <b>{dayjs(clientConnector.Log.CompletedAtUtc).fromNow()}</b>
            </small>
          )}
        </div>
      );
    }

    if (!clientConnector.Log?.CompletedAtUtc && !clientConnector.Log?.ManuallyTriggeredAtUtc)
      return <small className="text-muted">Never started</small>;

    return (
      <small className="text-muted">
        {clientConnector.Log?.CompletedAtUtc ? 'Completed' : 'Started'}{' '}
        <b>{dayjs(clientConnector.Log?.CompletedAtUtc ?? clientConnector.Log?.ManuallyTriggeredAtUtc).fromNow()}</b>
      </small>
    );
  };

  const renderBody = () => {
    if (
      clientConnector.Status === 'DISCONNECTED' &&
      clientConnector.Log?.RefreshDetail === 'Successfully refreshed.'
    ) {
      return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <img src="/img/sync.svg" alt="Sync" className="sync-icon" />
        </div>
      );
    }
    if (clientConnector.Status === 'DISCONNECTED') {
      if (isRefreshing) {
        return (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <img src="/img/sync.svg" alt="Sync" className="sync-icon" />
          </div>
        );
      }
      return (
        <div>
         {/* <small> */}
          {clientConnector.Log && (
            <span>
              {!isRefreshing && (
                <>
                  <br />
                  <b>Refresh detail:</b>
                  <div className="d-flex align-items-center">
                    <small
                      className="text-truncate"
                      style={{
                        maxWidth: '90%',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      title={rawDetail}
                    >
                      {truncatedDetail}
                    </small>

                    {/* If truncated, show '... more' link */}
                    {truncated && (
                      <span
                        className="ms-1 text-primary"
                        style={{ cursor: 'pointer', fontSize: '0.9em' }}
                        onClick={() => setShowModal(true)}
                      >
                        more
                      </span>
                    )}

                    {/* Copy button if there's a real detail */}
                    {clientConnector.Log?.RefreshDetail && (
                      <button
                        className="ms-2 btn btn-outline-secondary btn-xs btn-round"
                        onClick={onCopyRefreshDetailToClipboard}
                        title="Copy full message"
                      >
                        {showCopied ? (
                          <span className="material-symbols-rounded">check</span>
                        ) : (
                          <span className="material-symbols-rounded">content_copy</span>
                        )}
                      </button>
                    )}
                  </div>
                </>
              )}
            </span>
          )}
        {/* </small> */}
        </div>
      );
    }

    if (!users.length) return '';

    return (
      <div className="status-label-stack">
        {lodash
          .take(users, 10)
          .filter((_: any) => _)
          .map((user: any, index: number) => {
            const baseColor = colors.randomFromString(user.FirstName + user.LastName);
            const backgroundColor = colors
              .blend(baseColor, new colors([255, 255, 255]), 'hex', 0.9)
              ._hex;
            return (
              <span
                className="status-label"
                key={index}
                style={{ backgroundColor }}
                title={`${user.FirstName} ${user.LastName}`}
              >
                {user.FirstName.charAt(0)}
                {user.LastName?.charAt(0)}
              </span>
            );
          })}
        {users.length > 10 && <span className="status-label additional">+ {users.length - 10}</span>}
      </div>
    );
  };

  return (
    <div
      className={`tw-shadow tw-transition-all hover:tw-shadow-lg hover:tw-ring tw-ring-offset-1 tw-ring-blue-500 tw-no-underline tw-font-semibold !tw-text-slate-900 card card-sm ${
        clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED'
          ? 'disabled'
          : ''
      }`}
      style={{ height: 230 }}
    >
      <div className="card-header align-items-start">
        <div style={{ maxWidth: '80%' }}>
          <div className="d-flex flex-column gap-3">
            <span className="text-uppercase fw-bold">
              {(clientConnector.Status === 'DELETED' && (
                <span
                  className="p-1 px-2 fs-11 text-danger bg-danger bg-opacity-10 rounded-1 d-inline"
                  style={{ marginLeft: '-0.2rem' }}
                >
                  deleted
                </span>
              )) ||
                (clientConnector.Status === 'DISCONNECTED' && (
                  <span
                    className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline"
                    style={{ marginLeft: '-0.2rem' }}
                  >
                    disconnected
                  </span>
                )) ||
                (clientConnector.Status !== 'DISCONNECTED' && (
                  <span
                    className="p-1 px-2 fs-11 text-success bg-success bg-opacity-10 rounded-1 d-inline"
                    style={{ marginLeft: '-0.2rem' }}
                  >
                    connected
                  </span>
                ))}
            </span>
            <span className="d-flex flex-column">
              {clientConnector.Name !== clientConnector.OriginalName && (
                <small className="fw-bold darker-text fs-11">{clientConnector.OriginalName}</small>
              )}
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: 'inline-block',
                }}
              >
                {clientConnector.Name}
              </span>
              {renderStatusText()}
            </span>
          </div>
        </div>
        <span className="ms-auto">{renderStatusIcon()}</span>
      </div>
      <div className="card-body">{renderBody()}</div>
      <div className="card-footer gap-2 mt-auto">
        <div className="d-flex flex-row gap-1">
          {clientConnector.Status !== 'DISCONNECTED' && clientConnector.Status !== 'DELETED' && (
            <button className="btn btn-sm btn-primary" onClick={() => onAssignUserClick(clientConnector)}>
              Manage users
            </button>
          )}
          {clientConnector.Status !== 'DELETED' && store.user.roleLevel <= 2 && (
            <div className="d-flex flex-row gap-2 flex-grow ms-auto">
              <button
                className="btn btn-sm btn-round btn-secondary"
                title="Edit"
                onClick={() => onEditClientConnectorClick(clientConnector)}
              >
                <span className="material-symbols-rounded">edit</span>
              </button>
              <button
                className="btn btn-sm btn-round btn-danger"
                title="Delete"
                onClick={() => onDeleteClientConnectorClick(clientConnector)}
              >
                <span className="material-symbols-rounded">delete</span>
              </button>
              {clientConnector.Status !== 'DISCONNECTED' && (
                <button
                  className="btn btn-sm btn-round btn-outline-danger"
                  title="Disconnect"
                  onClick={() => onDisconnectClientConnectorClick(clientConnector)}
                >
                  <span className="material-symbols-rounded">link_off</span>
                </button>
              )}
              {connectorType === 'xero' && clientConnector.Log?.Forbidden && (
                <button
                  className="btn btn-sm btn-round btn-primary"
                  title="Retry"
                  onClick={() => onProcessClientConnectorClick(clientConnector)}
                >
                  <span className="material-symbols-rounded">refresh</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div
          className="modal show d-block"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1050,
          }}
        >
          <div className="modal-dialog" style={{ marginTop: '10vh' }}>
            <div className="modal-content">
              <div
                className="modal-header d-flex align-items-center justify-content-between"
                style={{ backgroundColor: '#0e7bbb' }}
              >
                <h5 className="modal-title mb-0" style={{ color: '#fff' }}>
                  Full Refresh Detail
                </h5>
                <div className="d-flex align-items-center gap-2">
                  <button
                    type="button"
                    className="btn btn-outline-light btn-sm"
                    onClick={onCopyRefreshDetailToClipboard}
                  >
                    {showCopied ? 'Copied!' : 'Copy'}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-light btn-sm"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <p style={{ fontSize: '1rem' }}>{rawDetail}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Business2CloudConnectorManageCard;
