import { useContext, MouseEvent, useState } from 'react';
import { Store } from '../store/Store';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { PAGE_URLS } from '../routes/routes';

import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import B2CClientConnector from '../interfaces/B2CClientConnector';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const REFRESH_DETAIL_MAX_LENGTH = 50;

const Business2CloudConnectorManageCard = ({
  clientConnector,
  onProcessClientConnectorClick,
}: {
  clientConnector: B2CClientConnector;
  onProcessClientConnectorClick: (
    event: MouseEvent<HTMLButtonElement>,
    clientConnector: B2CClientConnector,
    fullTransactionSync: boolean
  ) => Promise<void>;
}) => {
  const [searchParams] = useSearchParams();
  const { connectorType } = useParams();

  // 1) Introduce modal state
  const [showModal, setShowModal] = useState(false);

  const [showCopied, setShowCopied] = useState(false);

  const storeContext = useContext(Store);
  const search = searchParams.get('search') as string;
  const currentRole = jwt<any>(storeContext?.store?.jwt)?.Role?.toLowerCase() ?? '';

  let manuallyTriggeredAtUtcCheck = clientConnector.Log?.ManuallyTriggeredAtUtc
    ? new Date(new Date(clientConnector.Log.ManuallyTriggeredAtUtc).getTime() + 1000 * 60 * 30)
    : new Date();

  let automaticallyTriggeredAtUtcCheck = clientConnector.Log?.AutomaticallyTriggeredAtUtc
    ? new Date(new Date(clientConnector.Log.AutomaticallyTriggeredAtUtc).getTime() + 1000 * 60 * 30)
    : new Date();

  const hasWarning =
    !clientConnector.Log?.CompletedAtUtc &&
    ((!!clientConnector.Log?.ManuallyTriggeredAtUtc && manuallyTriggeredAtUtcCheck < new Date()) ||
      (automaticallyTriggeredAtUtcCheck > manuallyTriggeredAtUtcCheck &&
        !!clientConnector.Log?.AutomaticallyTriggeredAtUtc &&
        automaticallyTriggeredAtUtcCheck < new Date()));

  const allowSynchronisation =
    !clientConnector.Log ||
    !!clientConnector.Log?.CompletedAtUtc ||
    (!!clientConnector.Log?.ManuallyTriggeredAtUtc && manuallyTriggeredAtUtcCheck < new Date()) ||
    (automaticallyTriggeredAtUtcCheck > manuallyTriggeredAtUtcCheck &&
      !!clientConnector.Log?.AutomaticallyTriggeredAtUtc &&
      automaticallyTriggeredAtUtcCheck < new Date());

  const isRefreshing = !!clientConnector.Log?.ManuallyTriggeredAtUtc && !clientConnector.Log?.CompletedAtUtc;

  const getStatusExplanation = () => {
    if (['DISCONNECTED', 'DELETED'].includes(clientConnector.Status ?? '')) {
      return '';
    }
    if (hasWarning && !clientConnector.Log?.HasError) {
      return 'Taking longer than expected.';
    }
    if (!hasWarning && clientConnector.Log?.HasError) {
      return 'Encountered an error.';
    }
    if (isRefreshing) {
      return 'Currently refreshing...';
    }
    if (clientConnector.Log?.CompletedAtUtc) {
      return 'Connector is up to date.';
    }
    return 'Pending or unknown status.';
  };

  const renderStatusIcon = () => {
    if (['DISCONNECTED', 'DELETED'].includes(clientConnector.Status ?? '')) {
      return <></>;
    }
    if (hasWarning && !clientConnector.Log?.HasError) {
      return <span className="material-symbols-rounded user-select-none warning">warning</span>;
    } else if (!hasWarning && clientConnector.Log?.HasError) {
      return <span className="material-symbols-rounded user-select-none alert">error</span>;
    }
    if (clientConnector.Log && !clientConnector.Log?.CompletedAtUtc) {
      return <span className="material-symbols-rounded user-select-none pending">schedule</span>;
    }
    if (clientConnector.Log?.CompletedAtUtc) {
      return <span className="material-symbols-rounded user-select-none active">check_circle</span>;
    }
    return <span className="material-symbols-rounded user-select-none pending">pending</span>;
  };

  const renderStatusText = () => {
    if (['DISCONNECTED', 'DELETED'].includes(clientConnector.Status ?? '')) return <></>;
    if (!clientConnector.Log?.CompletedAtUtc && !clientConnector.Log?.ManuallyTriggeredAtUtc) {
      return (
        <small className="light-text">
          <div>Never started</div>
        </small>
      );
    }
    return (
      <small className="dark-text">
        {clientConnector.Log?.CompletedAtUtc ? 'Completed' : 'Started'}{' '}
        <b>
          {dayjs(clientConnector.Log?.CompletedAtUtc ?? clientConnector.Log?.ManuallyTriggeredAtUtc).fromNow()}
        </b>
      </small>
    );
  };

  const renderProgressAnimation = () => {
    if (['DISCONNECTED', 'DELETED'].includes(clientConnector.Status ?? '')) return null;
    if (isRefreshing) {
      return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <img src="/img/sync.svg" alt="Sync" className="sync-icon" />
        </div>
      );
    }
    return null;
  };

  const onCopyRefreshDetailToClipboard = () => {
    if (clientConnector.Log?.RefreshDetail) {
      navigator.clipboard.writeText(clientConnector.Log.RefreshDetail);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 3000);
    }
  };

  // We hide refresh detail for these statuses
  const REFRESH_STATUSES_TO_HIDE = ['CONNECTED', 'ACTIVE'];
  // const REFRESH_STATUSES_TO_HIDE = ['CONNECTED', 'ACTIVE'];
  const shouldHideRefreshDetail = REFRESH_STATUSES_TO_HIDE.includes(
    (clientConnector.Status ?? '').toUpperCase()
  );

  // Substring approach
  const truncateRefreshDetail = (text: string) => {
    if (!text) return 'No detail';
    return text.length > REFRESH_DETAIL_MAX_LENGTH
      ? text.slice(0, REFRESH_DETAIL_MAX_LENGTH) + '...'
      : text;
  };

  const isDetailTruncated = (text: string) =>
    text && text.length > REFRESH_DETAIL_MAX_LENGTH;

  const renderBody = () => {
    if (clientConnector.Status === 'DELETED') return '';

    if (clientConnector.Status === 'DISCONNECTED') {
      return (
        <small>
          {currentRole.indexOf('admin') >= 0 && (
            <span>
              <b>To reconnect, please go to the </b>
              <b>
                <Link
                  to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE.replace(
                    ':connectorType',
                    connectorType as string
                  )}
                  style={{ fontSize: '15px' }}
                >
                  Manage
                </Link>
              </b>
              <b> tab.</b>
            </span>
          )}
        </small>
      );
    }

    if (clientConnector.Log?.Forbidden) {
      return (
        <small className="d-flex flex-column gap-1 text-warning">
          <span>
            <b>This connector may have been disconnected.</b>
          </span>
          {currentRole.indexOf('admin') >= 0 && (
            <span>
              You may need to re-authorise or disconnect fully from the{' '}
              <Link
                to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE.replace(
                  ':connectorType',
                  connectorType as string
                )}
              >
                Manage
              </Link>{' '}
              tab.
            </span>
          )}
        </small>
      );
    }

    if (hasWarning) {
      const isCin7 = connectorType?.toLowerCase() === 'cin7';
      return (
        <small className="d-flex flex-column gap-1 text-warning">
          <span>
            <b>
              {isCin7
                ? "Cin7 does take longer to sync than the other connectors. They do offer a Flexi API purchase which allows for faster syncs."
                : "This connector seems to be taking longer than expected."}
            </b>
          </span>
          <span>
            {allowSynchronisation
              ? isCin7 
                ? "You can refresh to check the latest status"
                : "Please try refreshing it"
              : "You will be able to retry in few minutes"}
          </span>
        </small>
      );
    }
  };

  // Filter logic
  const filterMatches =
    !search ||
    clientConnector.Name.toLowerCase().includes(search.toLowerCase());
  if (!filterMatches) return <></>;

  const rawDetail = clientConnector.Log?.RefreshDetail || 'No detail';
  const truncatedDetail = truncateRefreshDetail(rawDetail);
  const truncated = isDetailTruncated(rawDetail);

  return (
    <>
      <div
        className={`tw-shadow tw-transition-all hover:tw-shadow-lg hover:tw-ring tw-ring-offset-1 tw-ring-blue-500 tw-no-underline tw-font-semibold !tw-text-slate-900 card card-sm ${
          ['DISCONNECTED', 'DELETED'].includes(clientConnector.Status ?? '')
            ? 'disabled'
            : ''
        }`}
        style={{ height: 260 }}
      >
        <div className="card-header align-items-start">
          <div style={{ maxWidth: '80%' }}>
            <div className="d-flex flex-column gap-3">
              <span className="text-uppercase fw-bold">
                {(clientConnector.Status === 'DELETED' && (
                  <span
                    className="p-1 px-2 fs-11 text-danger bg-danger bg-opacity-10 rounded-1 d-inline"
                    style={{ marginLeft: '-0.2rem' }}
                  >
                    deleted
                  </span>
                )) ||
                  (clientConnector.Status === 'DISCONNECTED' && (
                    <span
                      className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline"
                      style={{ marginLeft: '-0.2rem' }}
                    >
                      disconnected
                    </span>
                  )) ||
                  (clientConnector.Log?.Forbidden && (
                    <span
                      className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline"
                      style={{ marginLeft: '-0.2rem' }}
                    >
                      disconnected
                    </span>
                  )) ||
                  (!['DISCONNECTED', 'DELETED'].includes(
                    clientConnector.Status ?? ''
                  ) && (
                    <span
                      className="p-1 px-2 fs-11 text-success bg-success bg-opacity-10 rounded-1 d-inline"
                      style={{ marginLeft: '-0.2rem' }}
                    >
                      Connected
                      {/* {clientConnector.Status?.toLowerCase()} */}
                    </span>
                  ))}
              </span>
              <span className="d-flex flex-column">
                {clientConnector.Name !== clientConnector.OriginalName && (
                  <small className="fw-bold darker-text fs-11">
                    {clientConnector.OriginalName}
                  </small>
                )}
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                  }}
                >
                  {clientConnector.Name}
                </span>
                {renderStatusText()}
              </span>
            </div>
          </div>
          <span className="ms-auto" title={getStatusExplanation()}>
            {renderStatusIcon()}
          </span>
        </div>

        <div className="card-body">
          {renderBody()}
          {renderProgressAnimation()}

          {/* Hide refresh detail if status is CONNECTED/ACTIVE or if refreshing */}
          {!shouldHideRefreshDetail && !isRefreshing && (
            <>
              <br />
              <b>Refresh detail:</b>
              <div className="d-flex align-items-center">
                <small
                  className="text-truncate"
                  style={{
                    maxWidth: '90%',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title={rawDetail}
                >
                  {truncatedDetail}
                </small>

                {/* If truncated, show '... more' link */}
                {truncated && (
                  <span
                    className="ms-1 text-primary"
                    style={{ cursor: 'pointer', fontSize: '0.9em' }}
                    onClick={() => setShowModal(true)}
                  >
                    more
                  </span>
                )}

                {/* Copy button if there's a real detail */}
                {clientConnector.Log?.RefreshDetail && (
                  <button
                    className="ms-2 btn btn-outline-secondary btn-xs btn-round"
                    onClick={onCopyRefreshDetailToClipboard}
                    title="Copy full message"
                  >
                    {showCopied ? (
                      <span className="material-symbols-rounded">check</span>
                    ) : (
                      <span className="material-symbols-rounded">
                        content_copy
                      </span>
                    )}
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        <div className="card-footer gap-2 mt-auto">
          {!['DISCONNECTED', 'DELETED'].includes(clientConnector.Status ?? '') && (
            <div className="flex flex-row gap-2">
              <button
                className="btn btn-sm btn-primary justify-content-center"
                disabled={!allowSynchronisation}
                onClick={(event) =>
                  onProcessClientConnectorClick(event, clientConnector, false)
                }
              >
                {clientConnector.Log?.Forbidden
                  ? 'Retry'
                  : currentRole.indexOf('superadmin') > -1
                  ? 'Incremental'
                  : 'Refresh'}
              </button>
              {!clientConnector.Log?.Forbidden &&
                currentRole.indexOf('superadmin') > -1 && (
                  <button
                    className="btn btn-sm btn-outline-primary justify-content-center ms-auto"
                    disabled={!allowSynchronisation}
                    onClick={(event) =>
                      onProcessClientConnectorClick(event, clientConnector, true)
                    }
                  >
                    Full
                  </button>
                )}
            </div>
          )}
        </div>
      </div>

      {/* Modal for full detail */}
      {showModal && (
        <div
          className="modal show d-block"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1050,
          }}
        >
          <div className="modal-dialog" style={{ marginTop: '10vh' }}>
            <div className="modal-content">
              <div
                className="modal-header d-flex align-items-center justify-content-between"
                style={{ backgroundColor: '#0e7bbb' }}
              >
                <h5 className="modal-title mb-0" style={{ backgroundColor: 'transparent', color: '#fff' }}>
                  Full Refresh Detail
                </h5>
                <div className="d-flex align-items-center gap-2">
                  <button
                    type="button"
                    className="btn btn-outline-light btn-sm"
                    onClick={onCopyRefreshDetailToClipboard}
                  >
                    {showCopied ? 'Copied!' : 'Copy'}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-light btn-sm"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <p>{rawDetail}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Business2CloudConnectorManageCard;
