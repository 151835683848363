import { createRef, useEffect, useReducer, useState } from 'react';
import { withRouter } from '../routes/withRouter';
import { Link, useParams } from 'react-router-dom';
import { ApiService } from '../services/apiService';
import { PAGE_URLS } from '../routes/routes';

import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';

const ResetPasswordRedeem = (props: any) => {
  console.log('sdfsdfsfd');

  const formRef = createRef<any>();

  const { resetPasswordRedeemToken } = useParams();

  const reducer = (state: any, value: any) => ({ ...state, [value.id]: value.value });

  const [error, setError] = useState('');
  const [hideError, setHideError] = useState(false);
  const [errorSticky, setErrorSticky] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showInvalidState, setShowInvalidState] = useState(false);
  const [showValidState, setShowValidState] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorTop, setErrorTop] = useState(0);
  const [model, setModel] = useReducer(reducer, {
    password: '',
    confirmPassword: '',
  });

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };
  const resetPassword = async () => {
    setShowLoader(true);
    const result = await ApiService.getInstance().resetPassword({
      password: model.password,
      token: resetPasswordRedeemToken,
    });
    if (result.status === 403) setShowInvalidState(true);
    else if (result.status === 400 || result.status === 500) setError(result?.message);
    else setShowSuccess(true);
    setShowLoader(false);
  };
  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);
  const onFormInputChange = (event: any) => setModel(event.target);

  const validateResetPasswordRedeemToken = async () => {
    const response = await ApiService.getInstance().validateResetPasswordRedeemToken(resetPasswordRedeemToken as string);

    if (response.status === 403) setShowInvalidState(true);
    else if (response.status === 400 || response.status === 500) setError(response.message);
    else setShowValidState(true);
  };

  useEffect(() => {
    validateResetPasswordRedeemToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });
  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  if (showSuccess) {
    return (
      <div className='login-container container'>
        <div className='row justify-content-center'>
          <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
            <div className='m-5'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src='/img/larasoft-logo.svg' alt='Larasoft Logo' height='30px' />
                <span className='text-center mt-4'>
                  {props.setOnly ? 'Set' : 'Reset'} your <b>Business2Cloud</b> password
                </span>
              </div>
            </div>
            <div className='card card-sm mb-5' ref={formRef}>
              <div className='card-body'>
                <div className='form'>
                  <FormSection>
                    <h5>Password {props.setOnly ? 'set' : 'reset'}</h5>
                    <span>Your password has been successfully {props.setOnly ? 'set' : 'reset'}.</span>
                  </FormSection>
                  <FormSection>
                    <a href={PAGE_URLS.SIGNIN} className='btn btn-sm btn-primary me-auto'>
                      <span className='material-symbols-rounded'>arrow_back_ios</span>
                      Return to sign in
                    </a>
                  </FormSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showInvalidState) {
    return (
      <div className='login-container container'>
        <div className='row justify-content-center'>
          <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
            <div className='m-5'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src='/img/larasoft-logo.svg' alt='Larasoft Logo' height='30px' />
                <span className='text-center mt-4 text-nowrap light-text'>
                  {props.setOnly ? 'Set' : 'Reset'} your <b>Business2Cloud</b> password
                </span>
              </div>
            </div>
            <div className='card card-sm mb-5'>
              <div className='card-body'>
                <div className='form'>
                  <FormSection>
                    <h5>{props.setOnly ? 'Set' : 'Reset'} password request invalid</h5>
                    <span>
                      We're sorry, but this {props.setOnly ? 'set' : 'reset'} password request has either expired or is invalid. If you would still
                      like to reset your password, please go <Link to='/reset-password'>here</Link>.
                    </span>
                    <a href={PAGE_URLS.SIGNIN} className='btn btn-sm btn-primary me-auto mt-3'>
                      <span className='material-symbols-rounded'>arrow_back_ios</span>
                      Return to sign in
                    </a>
                  </FormSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showValidState) {
    return (
      <div className='login-container container'>
        <div className='row justify-content-center'>
          <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
            <div className='m-5'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src='/img/larasoft-logo.svg' alt='Larasoft Logo' height='30px' />
                <span className='text-center mt-4 text-nowrap light-text'>
                  {props.setOnly ? 'Set' : 'Reset'} your <b>Business2Cloud</b> password
                </span>
              </div>
            </div>
            <div className='card card-sm mb-5'>
              <div className='card-body'>
                <div className='form'>
                  {error && (
                    <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                      <span>
                        {error}
                        <span className='material-symbols-rounded' onClick={onErrorHideClick}>
                          cancel
                        </span>
                      </span>
                    </div>
                  )}
                  <div>
                    <div className='form-section'>
                      <Form onSubmit={resetPassword}>
                        <FormSection>
                          <div className='form-section-header'>
                            <h5>{props.setOnly ? 'Set' : 'Reset'} your password</h5>
                            <span>
                              Please provide us with a new password that is at least six characters long, includes one number or special character,
                              and is not a commonly used password.
                            </span>
                          </div>
                          <FormInput
                            id='password'
                            placeholder='Password'
                            type='password'
                            required={true}
                            value={model.password}
                            onChange={onFormInputChange}
                          />
                          <FormInput
                            id='confirmPassword'
                            placeholder='Confirm Password'
                            type='password'
                            required={true}
                            value={model.confirmPassword}
                            match='password'
                            onChange={onFormInputChange}
                          />
                          <button className='btn btn-sm btn-secondary me-auto mt-3' type='submit'>
                            {props.setOnly ? 'Set' : 'Reset'} password
                          </button>
                        </FormSection>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              {showLoader && (
                <div className='card-loader'>
                  <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                    <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showLoader) return <div></div>;

  return (
    <div className="min-h-screen w-full overflow-hidden" style={{
      backgroundColor: '#0e7bbb',
      backgroundImage: 'url(/img/larasoft-web-background.svg)',
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}>
      <div className="relative min-h-screen w-full d-flex align-items-center">
        <div className="login-container container pb-5">
          <div className="row justify-content-start">
            <div className="col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4 offset-md-1">
              <div className="m-4">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img src="/img/larasoft-logo.svg" alt="Larasoft Logo" height="30px" />
                  <span className="text-center mt-4 text-nowrap light-text">
                    {props.setOnly ? 'Set' : 'Reset'} your <b>Business2Cloud</b> password
                  </span>
                </div>
              </div>
              <div className="card card-sm">
                <div className="card-body">
                  <div className="form">
                    {error && (
                      <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                        <span>
                          {error}
                          <i className="material-symbols-rounded" onClick={onErrorHideClick}>
                            cancel
                          </i>
                        </span>
                      </div>
                    )}
                    <div>
                      <Form onSubmit={resetPassword}>
                        <FormSection>
                          <div className="form-section-header">
                            <h5>{props.setOnly ? 'Set' : 'Reset'} your password</h5>
                            <span>
                              Please provide us with a new password that is at least six characters long, includes one number or special character,
                              and is not a commonly used password.
                            </span>
                          </div>
                          <FormInput
                            id="password"
                            placeholder="Password"
                            type="password"
                            required={true}
                            value={model.password}
                            onChange={onFormInputChange}
                          />
                          <FormInput
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            type="password"
                            required={true}
                            value={model.confirmPassword}
                            match="password"
                            onChange={onFormInputChange}
                          />
                          <button className="btn btn-sm btn-secondary justify-content-center flex-grow-1" type="submit">
                            {props.setOnly ? 'Set' : 'Reset'} password
                          </button>
                        </FormSection>
                      </Form>
                    </div>
                  </div>
                </div>
                {showLoader && (
                  <div className="card-loader">
                    <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                    </svg>
                  </div>
                )}
              </div>
              <div className="d-flex flex-column mt-4 gap-2">
                <span className="d-flex justify-content-center light-text">
                  Remember your password?&nbsp;<Link to="/signin" className="text-white">Sign in</Link>&nbsp;now.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter((props) => <ResetPasswordRedeem {...props} />);
