import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
// import { REDUCER_ACTION_SET } from '../store/types';
// import { PAGE_URLS } from '../routes/routes';
// import { forEachTrailingCommentRange } from 'typescript';
// import { SECURITY_SCREENS } from './SecurityGroups';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

const GroupPermissionVersionMilestone = (props: any) => {
  const { back, valuesInit, next } = props;
  // const { groupName, groupSelected, back, valuesInit, next } = props;

  const s = React.useContext(Store);
  const store = s.store;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = s.dispatch;

  // const [users, setUsers] = React.useState([]);
  // const [areas, setAreas] = React.useState([]);
  // const [subAreas, setSubAreas] = React.useState([]);
  const [values, setValues] = React.useState<any>(valuesInit);
  const [mappingCount, setMappingCount] = React.useState<number>(0);
  const [versions, setVersions] = React.useState([]);
  const [milestones, setMilestones] = React.useState<any>({});

  const getVersions = async () => {
    let versionsFetched = await api.getVersions(
      store?.organisationSelected?.organisationId
    );
    if (versionsFetched) {
      console.log('VERSIONS ', versionsFetched);

      // sort versions
      // const newValues = { ...values };
      versionsFetched = sortList(versionsFetched, ['Name'], SORT_DIRECTION.ASC);
      // newValues.sortListVersionDirection = SORT_DIRECTION.ASC;
      // sort milestones
      versionsFetched.forEach((version: any) => {
        if (!Array.isArray(version.Milestones)) return;
        const sortedList = sortList(
          version.Milestones,
          ['Name'],
          SORT_DIRECTION.ASC
        );
        version.Milestones = sortedList;
      });
      // newValues.sortListMilestoneDirection = SORT_DIRECTION.ASC;
      // setValues(newValues);

      setVersions(versionsFetched);
    }

    if (versionsFetched && Array.isArray(versionsFetched)) {
      getMilestones(versionsFetched as [{ Id: string }]);
    }
  };

  const getMilestones = async (versions: [{ Id: string }]) => {
    const mileStonesFetched: any = { ...milestones };
    for (let i = 0; i < versions.length; i++) {
      const data = await api.getMilestones(versions[i].Id);
      mileStonesFetched[versions[i].Id] = data;
    }
    setMilestones(mileStonesFetched);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getVersions();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //if (!groupSelected) return;

    if (
      !valuesInit ||
      !valuesInit.groupSelected ||
      !valuesInit.groupSelected.B2SGroupVersionMilestones ||
      valuesInit.firstViewOfScreenVersion
    )
      return;

    const groupSelected = valuesInit.groupSelected;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const permissions = groupSelected.B2SGroupVersionMilestones;
    const newGroupMappings: any = {
      groupMappings: { ...values.groupMappings },
    };
    newGroupMappings.groupMappings.milestone = {};

    groupSelected.B2SGroupVersionMilestones.forEach(
      (permission: any, i: number) => {
        if (i === 0) {
          newGroupMappings.groupMappings['version'] = permission.B2SVersionId;
        }

        if (
          permission.B2SVersionId &&
          !newGroupMappings.groupMappings.milestone[permission.B2SVersionId]
        ) {
          newGroupMappings.groupMappings.milestone[permission.B2SVersionId] = {
            checked: {},
          };
        }
        if (permission.B2SMilestoneId) {
          newGroupMappings.groupMappings.milestone[
            permission.B2SVersionId
          ].checked[permission.B2SMilestoneId] = permission.B2SMilestoneId
            ? true
            : false;
        }

        if (permission.B2SMilestoneAllCurrentAndFuture) {
          newGroupMappings.groupMappings.milestone[permission.B2SVersionId] =
            !newGroupMappings.groupMappings.milestone[permission.B2SVersionId]
              ? {}
              : newGroupMappings.groupMappings.milestone[
                  permission.B2SVersionId
                ];

          newGroupMappings.groupMappings.milestone[
            permission.B2SVersionId
          ].milestoneOptions = 'allCurrentAndFuture';
        }
      }
    );

    setValues({
      ...values,
      ...newGroupMappings,
      firstViewOfScreenVersion: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesInit]);

  enum ValueStoreTypes {
    STRING,
    ARRAY,
  }

  const handleChange = (
    e: any,
    names: string[] | string,
    valueStoreType?: ValueStoreTypes
  ) => {
    const newValues = { ...values };

    if (!Array.isArray(names)) {
      names = [names];
    }

    let newValuesStore = newValues;

    for (let i = 0; i < names.length - 1; i++) {
      const name = names[i];
      if (!newValuesStore[name]) {
        newValuesStore[name] = {};
      }
      newValuesStore = newValuesStore[name];
    }

    if (
      valueStoreType === ValueStoreTypes.ARRAY &&
      !Array.isArray(newValuesStore[names[names.length - 1]])
    ) {
      newValuesStore[names[names.length - 1]] = [];
    }
    if (valueStoreType === ValueStoreTypes.ARRAY) {
      newValuesStore[names[names.length - 1]].push(e.target.value);
    } else {
      newValuesStore[names[names.length - 1]] = e.target.value;
    }

    setValues(newValues);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addGroup = async () => {
    // const result = await api.createUser(values.firstName, values.lastName, values.email, values.password, values.selectRole)
    //if (!values.groupMappings || !values.firstName) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = await api.addGroup(
      values.firstName,
      s.store?.organisationSelected?.organisationId
    ); // TODO handle multiple orgs
    const mappings = Object.keys(values.groupMappings);

    for (let i = 0; i < mappings.length; i++) {}
    // getUsers();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addAdditional = async () => {
    //
    // const result = await api.createUser(values.firstName, values.lastName, values.email, values.password, values.selectRole)
    // getUsers();
    setMappingCount(mappingCount + 1);
  };

  const setPermission = async () => {
    const newValues = { ...values };
    newValues['errors'] = [];

    // create new group or remove all permission for group if edit
    let result = null;
    let errorMessage = null;
    if (values.groupEdit && values.groupSelected && values.groupSelected.Id) {
      result = await api.updateGroup(
        values.firstName,
        s.store?.organisationSelected?.organisationId,
        values.groupSelected.Id
      ); // TODO handle multiple orgs

      if (result && result.error) {
        errorMessage = result.message
          ? result.message
          : 'error occurred adding group';
      } else if (!result || !result.Id) {
        errorMessage = 'error occurred adding group';
      }

      if (errorMessage) {
        newValues['errors'].push(errorMessage);
      }

      //result = { Id: values.groupSelected.Id };
      await api.deletePermissionAreaSubArea(values.groupSelected.Id);
      await api.deletePermissionVersionMilestone(values.groupSelected.Id);
    } else {
      result = await api.addGroup(
        values.firstName,
        s.store?.organisationSelected?.organisationId
      ); // TODO handle multiple orgs

      if (result && result.error) {
        errorMessage = result.message
          ? result.message
          : 'error occurred adding group';
      } else if (!result || !result.Id) {
        errorMessage = 'error occurred adding group';
      }

      if (errorMessage) {
        newValues['errors'].push(errorMessage);
      }
    }

    if (errorMessage) {
      setValues(newValues);
      return;
    }

    const groupId = result.Id;

    // global area subArea all current and future
    let subAreaAllCurrentAndFuture = null;
    if (
      values.groupMappings &&
      !values.groupMappings.area &&
      values.groupMappings.subAreaGlobal &&
      values.groupMappings.subAreaGlobal.subAreaOptions &&
      values.groupMappings.subAreaGlobal.subAreaOptions ===
        'allCurrentAndFuture'
    ) {
      subAreaAllCurrentAndFuture = true;
    }
    let areaAllCurrentAndFuture = null;
    // global area all current and future
    if (
      values.groupMappings &&
      !values.groupMappings.subAreaFlipped &&
      values.groupMappings.areaGlobal &&
      values.groupMappings.areaGlobal.areaOptions &&
      values.groupMappings.areaGlobal.areaOptions === 'allCurrentAndFuture'
    ) {
      areaAllCurrentAndFuture = true;
    }

    if (areaAllCurrentAndFuture && subAreaAllCurrentAndFuture) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await api.addPermissionAreaSubArea(
        groupId,
        undefined,
        undefined,
        areaAllCurrentAndFuture ? true : undefined,
        subAreaAllCurrentAndFuture ? true : undefined
      );
    }

    if (values.groupMappings && values.groupMappings.subArea) {
      //addPermissionAreaSubArea
      const areaIdKeys = Object.keys(values.groupMappings.subArea);

      for (let i = 0; i < areaIdKeys.length; i++) {
        const areaIdKey = areaIdKeys[i];
        const subAreaData = values.groupMappings.subArea[areaIdKey];

        if (
          !subAreaData.subAreaOptions ||
          subAreaData.subAreaOptions !== 'allCurrentAndFuture'
        ) {
          const subAreaKeys = Object.keys(
            values.groupMappings.subArea[areaIdKey].checked
          );

          for (let j = 0; j < subAreaKeys.length; j++) {
            if (
              values.groupMappings.subArea[areaIdKey].checked[
                subAreaKeys[j]
              ] === true
            ) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const result = await api.addPermissionAreaSubArea(
                groupId,
                areaIdKey,
                subAreaKeys[j],
                undefined,
                undefined
              );
            }
          }
        } else if (subAreaData.subAreaOptions === 'allCurrentAndFuture') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const result = await api.addPermissionAreaSubArea(
            groupId,
            areaIdKey,
            undefined,
            undefined,
            true
          );
        }
      }
    }

    //{values.groupMappings && values.groupMappings.areaFlipped && values.groupMappings.areaFlipped[`${subArea.Id}`]?.areaOptions === 'allCurrentAndFuture'}
    if (values.groupMappings && values.groupMappings.areaFlipped) {
      //addPermissionAreaSubArea
      const subAreaIdKeys = Object.keys(values.groupMappings.areaFlipped);

      for (let i = 0; i < subAreaIdKeys.length; i++) {
        const subAreaIdKey = subAreaIdKeys[i];
        const areaData = values.groupMappings.areaFlipped[subAreaIdKey];

        if (areaData.areaOptions === 'allCurrentAndFuture') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const result = await api.addPermissionAreaSubArea(
            groupId,
            undefined,
            subAreaIdKey,
            true,
            undefined
          );
        }
      }
    }

    if (values.groupMappings && values.groupMappings.milestone) {
      //addPermissionAreaSubArea
      const versionIdKeys = Object.keys(values.groupMappings.milestone);

      for (let i = 0; i < versionIdKeys.length; i++) {
        const versionIdKey = versionIdKeys[i];
        const milestoneData = values.groupMappings.milestone[versionIdKey];

        if (
          !milestoneData.milestoneOptions ||
          milestoneData.milestoneOptions !== 'allCurrentAndFuture'
        ) {
          const milestoneKeys = Object.keys(
            values.groupMappings.milestone[versionIdKey].checked
          );

          for (let j = 0; j < milestoneKeys.length; j++) {
            if (
              values.groupMappings.milestone[versionIdKey].checked[
                milestoneKeys[j]
              ] === true
            ) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const result = await api.addPermissionVersionMilestone(
                groupId,
                versionIdKey,
                milestoneKeys[j],
                undefined,
                undefined
              );
            }
          }
        } else if (milestoneData.milestoneOptions === 'allCurrentAndFuture') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const result = await api.addPermissionVersionMilestone(
            groupId,
            versionIdKey,
            undefined,
            false,
            true
          );
        }
      }
    }
    next({});
  };

  const sortListVersions = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(versions, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListVersionDirection = sortDirection;
    setValues(newValues);
    setVersions(sortedList as any);
  };

  const sortListMilestones = (sortDirection: SORT_DIRECTION) => {
    // sort sub areas
    versions.forEach((version: any) => {
      if (!Array.isArray(version.Milestones)) return;
      const sortedList = sortList(version.Milestones, ['Name'], sortDirection);
      version.Milestones = sortedList;
    });
    const newValues = { ...values };
    newValues.sortListMilestoneDirection = sortDirection;
    setValues(newValues);
    setVersions(versions as any);
  };

  const groupMappings = (groupMappingId: number) => {
    return (
      <Fragment>
        <div className="card__linking-container--left card-body border p-0">
          <div className="p-3 pb-0">
            <div className="has-tools">
              <h5 className="mb-0">Versions</h5>
              <div
                className="btn btn-outline-secondary btn-sm btn-sort-width"
                onClick={(e) => {
                  sortListVersions(
                    values.sortListVersionDirection === undefined ||
                      values.sortListVersionDirection === SORT_DIRECTION.ASC
                      ? SORT_DIRECTION.DEC
                      : SORT_DIRECTION.ASC
                  );
                }}
              >
                {values.sortListVersionDirection === undefined ||
                values.sortListVersionDirection === SORT_DIRECTION.ASC
                  ? 'Ascending'
                  : 'Descending'}
                <i
                  className={
                    values.sortListVersionDirection === undefined ||
                    values.sortListVersionDirection === SORT_DIRECTION.ASC
                      ? 'fa-solid fa-arrow-down-short-wide ms-1'
                      : 'fa-solid fa-arrow-up-wide-short ms-1'
                  }
                />
              </div>
            </div>
            <input
              type="text"
              className="form-control form-control-sm my-3"
              placeholder="Search Versions"
              onChange={(e) => {
                handleChange(e, 'filterVersion');
              }}
              value={values.filterVersion}
            />
          </div>
          <hr className="my-4 mb-0" />
          <div
            className="form-check__container form-check__container--left"
            style={{ maxHeight: 'calc(100vh - 630px)' }}
          >
            {versions.map((version: any) => {
              if (
                values.filterVersion &&
                values.filterVersion !== '' &&
                version.Name.toLowerCase().indexOf(
                  values.filterVersion.toLowerCase()
                ) === -1
              ) {
                return <Fragment></Fragment>;
              }

              return (
                <div className="form-check">
                  <div
                    className={(() => {
                      if (
                        !values.groupMappings ||
                        !values.groupMappings.milestone ||
                        !values.groupMappings.milestone[version.Id]
                      )
                        return;
                      if (
                        values.groupMappings.milestone[version.Id]
                          .milestoneOptions === 'allCurrentAndFuture'
                      )
                        return 'form-check__active-indicator';

                      if (!values.groupMappings.milestone[version.Id].checked)
                        return;
                      const milestoneKeys = Object.keys(
                        values.groupMappings.milestone[version.Id].checked
                      );

                      for (let i = 0; i < milestoneKeys.length; i++) {
                        if (
                          values.groupMappings.milestone[version.Id].checked[
                            milestoneKeys[i]
                          ]
                        ) {
                          return 'form-check__active-indicator';
                          // eslint-disable-next-line no-unreachable
                          break;
                        }
                      }
                    })()}
                  >
                    <input
                      autoComplete="off"
                      type="radio"
                      id={'version' + version.Id}
                      name={'version'}
                      className="form-check-input"
                      value={version.Id}
                      checked={
                        values.groupMappings &&
                        values.groupMappings?.version === version.Id
                      }
                      onChange={(e) => {
                        handleChange(e, ['groupMappings', 'version']);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={'version' + version.Id}
                    >
                      {version.Name}
                    </label>
                  </div>
                </div>
              );
            })}{' '}
          </div>
        </div>
        <div className="card__linking-container--middle bg-light border-top border-bottom d-flex align-items-center justify-content-center">
          <i className="fa-solid fa-arrow-right" />
        </div>

        <div className="card__linking-container--right card-body border p-0">
          <div className="p-3 pb-0">
            <div className="has-tools">
              <h5 className="mb-0">Milestones</h5>
              <div
                className="btn btn-outline-secondary btn-sm btn-sort-width"
                onClick={(e) => {
                  sortListMilestones(
                    values.sortListMilestoneDirection === undefined ||
                      values.sortListMilestoneDirection === SORT_DIRECTION.ASC
                      ? SORT_DIRECTION.DEC
                      : SORT_DIRECTION.ASC
                  );
                }}
              >
                {values.sortListMilestoneDirection === undefined ||
                values.sortListMilestoneDirection === SORT_DIRECTION.ASC
                  ? 'Ascending'
                  : 'Descending'}
                <i
                  className={
                    values.sortListMilestoneDirection === undefined ||
                    values.sortListMilestoneDirection === SORT_DIRECTION.ASC
                      ? 'fa-solid fa-arrow-down-short-wide ms-1'
                      : 'fa-solid fa-arrow-up-wide-short ms-1'
                  }
                />
              </div>
            </div>
            <input
              type="text"
              className="form-control form-control-sm my-3"
              placeholder="Search Milestones"
              onChange={(e) => {
                handleChange(e, 'filterMilestone');
              }}
              value={values.filterMilestone}
            />
          </div>
              
          {
          versions.map((version: any) => {
            if (
              !values.groupMappings ||
              values.groupMappings?.version !== version.Id
            )
              // eslint-disable-next-line array-callback-return
              return;
            if (!Array.isArray(version.Milestones))
              // eslint-disable-next-line array-callback-return
              return;

            const milestoneBoxes: any[] = [];

            milestoneBoxes.push(
              <Fragment>
                <div className="px-3">
                  <div className="form-check">
                    <input
                      autoComplete="off"
                      type="radio"
                      id={'allCurrentAndFuture' + version.Id}
                      name={'milestoneOptions' + version.Id}
                      className="form-check-input"
                      value={'allCurrentAndFuture'}
                      checked={
                        values.groupMappings &&
                        values.groupMappings.milestone &&
                        values.groupMappings.milestone[`${version.Id}`]
                          ?.milestoneOptions === 'allCurrentAndFuture'
                      }
                      onChange={(e) => {
                        handleChange(e, [
                          'groupMappings',
                          'milestone',
                          `${version.Id}`,
                          'milestoneOptions',
                        ]);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={'allCurrentAndFuture' + version.Id}
                    >
                      All current and future
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      autoComplete="off"
                      type="radio"
                      id={'allCurrent' + version.Id}
                      name={'milestoneOptions' + version.Id}
                      className="form-check-input"
                      value={'allCurrent'}
                      checked={
                        values.groupMappings &&
                        values.groupMappings.milestone &&
                        values.groupMappings.milestone[`${version.Id}`]
                          ?.milestoneOptions === 'allCurrent'
                      }
                      onChange={(e) => {
                        handleChange(e, [
                          'groupMappings',
                          'milestone',
                          `${version.Id}`,
                          'milestoneOptions',
                        ]);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={'allCurrent' + version.Id}
                    >
                      All current
                    </label>
                  </div>
                </div>
              </Fragment>
            );

            const milestoneBoxesMain: any = [];
            version.Milestones.forEach((milestone: any) => {
              if (
                values.filterMilestone &&
                values.filterMilestone !== '' &&
                milestone.Name.toLowerCase().indexOf(
                  values.filterMilestone.toLowerCase()
                ) === -1
              ) {
                return;
              }

              milestoneBoxesMain.push(
                <div className="form-check">
                  <input
                    autoComplete="off"
                    type="checkbox"
                    className="form-check-input"
                    id={'milestone' + version.Id + milestone.Id}
                    checked={
                      values.groupMappings &&
                      values.groupMappings.milestone &&
                      values.groupMappings.milestone[`${version.Id}`] &&
                      values.groupMappings.milestone[`${version.Id}`].checked &&
                      values.groupMappings.milestone[`${version.Id}`].checked[
                        milestone.Id
                      ]
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleChange(
                        {
                          target: { value: e.target.checked },
                        },
                        [
                          'groupMappings',
                          'milestone',
                          `${version.Id}`,
                          'checked',
                          milestone.Id,
                        ]
                      );
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={'milestone' + version.Id + milestone.Id}
                  >
                    {milestone.Name}
                  </label>
                </div>
              );
            });
            milestoneBoxes.push(
              <Fragment>
                <hr className="my-4 mb-0" />
                <div
                  className="form-check__container form-check__container--right"
                  style={{ maxHeight: 'calc(100vh - 691px)' }}
                >
                  {milestoneBoxesMain}
                </div>
              </Fragment>
            );
            return milestoneBoxes;
          })}
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      <div className="card steps-header" style={{ backgroundColor: '#F0F0F1' }}>
        <div className="steps-header__step active">1. Areas</div>
        <span />
        <div className="steps-header__step active">2. Subreas</div>
        <span />
        <div className="steps-header__step active">
          3. Versions &amp; Milestones
        </div>
        <span />
        <div className="steps-header__step">
          <i className="fas fa-check" />
        </div>
      </div>
      <div className="p-4 pb-0">
        <label htmlFor="groupName" className="mb-2">
          Security Group Name
        </label>
        <input
          type="text"
          className="form-control"
          id="groupName"
          name="groupName"
          placeholder="Security Group Name"
          onChange={(e) => handleChange(e, 'firstName')}
          value={values.firstName}
        />
      </div>
      <div>
        <div className="card__linking-container p-4">{groupMappings(0)}</div>
        {values.errors &&
          Array.isArray(values.errors) &&
          values.errors.length > 0 && (
            // <div className="mt-2">
            <div className="card__linking-container p-4">
              <label className="label--has-info">
                <small className="alert alert-danger p-2">
                  <i className="fa-solid fa-exclamation-circle me-2" />
                  <div className="float-end">
                    {values.errors.map((message: any) => {
                      return <div>{message}</div>;
                    })}
                  </div>
                </small>
              </label>
            </div>
          )}
        <div className="d-flex align-items-center justify-content-between px-4 pb-4">
          <button
            className="btn btn-outline-dark"
            onClick={() => {
              back(values);
            }}
          >
            <i className="fa-solid fa-arrow-left" />
            Back
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              setPermission();
            }}
          >
            <i className="fa-solid fa-check" />
            Create Security Group
          </button>
        </div>
      </div>
      {/* {(()=>{
          let count = 0;
          const display = [];
          do {
            display.push(groupMappings(count))
            count++;
          } while(count <= mappingCount);
          display.push(  <button onClick={() => {
            addAdditional();
          }}>Add additional area mapping</button>);
          return display;
        })()} */}
    </div>
  );
};

export default withRouter(GroupPermissionVersionMilestone);
