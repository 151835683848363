import {
  useContext,
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
  createRef,
} from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useInterval } from 'usehooks-ts';

import jwt from 'jwt-decode';
import B2CClientConnector from '../interfaces/B2CClientConnector';
import Business2CloudApiService from '../services/business2CloudApiService';
import Business2CloudConnectorConfigureCard from './Business2CloudConnectorConfigureCard';
import Form from './Form';
import FormSection from './FormSection';
import ClientConnectorConfigurationFormModal from './ClientConnectorConfigurationFormModal';

//Configure and configure card are the same as the manage tab
const Business2CloudConnectorConfiguration = () => {
  const { connectorType } = useParams();

  const storeContext = useContext(Store);
  const store = storeContext.store;
  const location = useLocation();
  const containerRef = createRef<any>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOrganisationId, setSelectedOrganisationId] =
    useState<number>();
  const [userList, setUsersList] = useState<any[]>();
  const [clientConnectorList, setClientConnectorList] =
    useState<B2CClientConnector[]>();
  const [assignUser, setAssignUser] = useState<boolean>(false);
  const [userSearchString, setUserSearchString] = useState<string>('');
  const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedClientConnector, setSelectedClientConnector] =
    useState<B2CClientConnector>();
  const [
    showClientConnectorConfigurationFormModal,
    setShowClientConnectorConfigurationFormModal,
  ] = useState(false);
  const [
    showDisconnectionConfirmationModal,
    setShowDisconnectionConfirmationModal,
  ] = useState(false);
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] =
    useState(false);
  const [
    showDisconnectAllConfirmationModal,
    setShowDisconnectAllConfirmationModal,
  ] = useState(false);
  const [error, setError] = useState('');
  const [hideError, setHideError] = useState(false);
  const [connectorTypeDefinition, setConnectorTypeDefinition] = useState<any>();
  const [disconnectionReason, setDisconnectionReason] = useState<string>('');

  const colors = require('ac-colors');
  const currentRole = jwt<any>(store?.jwt)?.Role?.toLowerCase() ?? '';

  const onScroll = () => containerRef?.current;

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    if (connectorType && store?.connectorTypes?.length) {
      const definition = store?.connectorTypes?.find(
        (_: any) => _.Type.toLowerCase() === connectorType.toLowerCase()
      );

      setConnectorTypeDefinition(definition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.connectorTypes]);

  useEffect(() => {
    if (connectorTypeDefinition && searchParams.get('new') === 'true') {
      searchParams.delete('new');
      setSearchParams(searchParams);

      onAddClientConnectorClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorTypeDefinition]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => {
    if (
      !searchParams.get('new') &&
      store?.organisationSelected?.organisationId === selectedOrganisationId
    ) {
      setShowLoader(false);
    }
  }, [
    store?.organisationSelected?.organisationId,
    selectedOrganisationId,
    searchParams,
  ]);

  useEffect(() => {
    refreshLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.organisationSelected?.organisationId, location, searchParams]);

  useEffect(() => {
    const error = searchParams.get('error');

    if (!!error) {
      if (error === 'unauthorised') {
        setError('The process was either cancelled or not allowed.');
        setError(
          `Adding the <b>${
            connectorType || 'external provider'
          }</b> connector was either cancelled or was not allowed.`
        );
      } else if (error === 'connector_exists') {
        setError('The process connot be completed.');
        setError(
          `The <b>${
            connectorType || 'external provider'
          }</b> connector you are trying to add already belongs to another organisation.</br>Please speak to your organisation administrator.`
        );
      } else {
        setError(
          `Something seems to have gone wrong while trying to add the <b>${
            connectorType || 'external provider'
          }</b> connector.<br /><br />Please try again.`
        );
      }

      searchParams.delete('error');

      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useInterval(() => refreshLists(), 10000);

  const disconnectClientConnector = async (
    clientConnector: B2CClientConnector,
    disconnectionReason?: string
  ) => {
    await fetch(
      `${process.env.REACT_APP_B2C_API_URL}/api/clients/${clientConnector.Client.Key}/${clientConnector.Key}`,
      {
        method: 'delete',
        body: null,
        headers: {
          authorization: 'Bearer ' + Business2CloudApiService.Instance.getJwt(),
        },
      }
    );

    const currentClientConnectorIndex =
      clientConnectorList?.findIndex((_) => _.Key === clientConnector.Key) ??
      -1;
    const updatedClientConnectorList = [...(clientConnectorList || [])];

    if (currentClientConnectorIndex === -1) return;

    updatedClientConnectorList[currentClientConnectorIndex] = {
      ...clientConnector,
      Status: 'DISCONNECTED',
      disconnectionReason: disconnectionReason || 'No reason provided',
    };

    setClientConnectorList(updatedClientConnectorList);
    setShowLoader(false);
  };

  const deleteClientConnector = async (clientConnector: B2CClientConnector) => {
    await fetch(
      `${process.env.REACT_APP_B2C_API_URL}/api/clients/${clientConnector.Client.Key}/${clientConnector.Key}`,
      {
        method: 'delete',
        body: null,
        headers: {
          authorization: 'Bearer ' + Business2CloudApiService.Instance.getJwt(),
        },
      }
    );

    await fetch(
      `${process.env.REACT_APP_PORTAL_API_URL}/business2cloud/clients/${store?.organisationSelected?.organisationId}/connectors/${clientConnector.Id}`,
      {
        method: 'delete',
        body: null,
        headers: {
          authorization: 'Bearer ' + Business2CloudApiService.Instance.getJwt(),
        },
      }
    );

    const currentClientConnectorIndex =
      clientConnectorList?.findIndex((_) => _.Key === clientConnector.Key) ??
      -1;
    const updatedClientConnectorList = [...(clientConnectorList || [])];

    if (currentClientConnectorIndex === -1) return;

    updatedClientConnectorList[currentClientConnectorIndex] = {
      ...clientConnector,
      Status: 'DELETED',
    };

    setClientConnectorList(updatedClientConnectorList);
    setShowLoader(false);
  };

  const refreshLists = async () => {
    let users = await ApiService.getInstance().getUsers(
      store?.organisationSelected?.organisationId
    );
    let clientConnectors =
      await Business2CloudApiService.Instance.getClientConnectorsByTypeAsync(
        store?.organisationSelected?.organisationId,
        connectorType as string
      );

    setUsersList(users);
    setClientConnectorList(
      clientConnectors.sort((a, b) =>
        (a.Name || a.Key).localeCompare(b.Name || b.Key)
      )
    );
    setSelectedOrganisationId(store?.organisationSelected?.organisationId);
  };

  const onAssignUserClick = (clientConnector: any) => {
    setSelectedUserList(
      userList?.filter(
        (user) =>
          !!clientConnector.Properties.find(
            (property: any) =>
              property.Name === 'portal.user' &&
              property.Value.indexOf(user.Id) >= 0
          )
      ) || []
    );
    setSelectedClientConnector(clientConnector);
    setAssignUser(true);
  };

  const onBackClick = () => {
    setShowSaveButton(false);
    setUserSearchString('');
    setSelectedClientConnector(undefined);
    setAssignUser(false);
    setShowLoader(false);
  };

  const onAssignUserSaveClick = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.currentTarget.disabled = true;

    setShowLoader(true);

    try {
      if (selectedClientConnector) {
        await Business2CloudApiService.Instance.assignClientConnectorUsersAsync(
          selectedClientConnector.Id,
          selectedUserList
        );
      }
    } catch {
      alert(
        'There was a problem with trying to assign the selected connectors. \r\n\r\nPlease try again, but if the problem persists, please contact LaraSoft.'
      );
    }

    await refreshLists();

    onBackClick();
  };

  const onSelectUserChanged = (
    event: ChangeEvent<HTMLInputElement>,
    user: any
  ) => {
    let tempSelectedUserList = [...selectedUserList];

    if (event.target.checked) {
      tempSelectedUserList.push(user);
    } else {
      let userIndex = tempSelectedUserList.findIndex((_) => _.Id === user.Id);
      tempSelectedUserList.splice(userIndex, 1);
    }

    setShowSaveButton(true);
    setSelectedUserList(tempSelectedUserList);
  };

  const onUserSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
    setUserSearchString(event.target.value);

  const onDisconnectAllClientConnectorClick = async () => {
    setDisconnectionReason('');
    setShowDisconnectAllConfirmationModal(true);
  };

  const onDisconnectClientConnectorClick = async (
    clientConnector: B2CClientConnector
  ) => {
    setSelectedClientConnector(clientConnector);
    setDisconnectionReason('');
    setShowDisconnectionConfirmationModal(true);
  };

  const onDeleteClientConnectorClick = async (
    clientConnector: B2CClientConnector
  ) => {
    setSelectedClientConnector(clientConnector);
    setShowDeletionConfirmationModal(true);
  };

  const onConfirmDisconnectAllClientConnectorClick = async () => {
    if (!clientConnectorList) return;

    setShowLoader(true);
    setShowDisconnectAllConfirmationModal(false);

    await Promise.all(
      clientConnectorList?.map((clientConnector) =>
        disconnectClientConnector(
          clientConnector,
          disconnectionReason || 'Batch disconnection'
        )
      )
    );

    setSelectedClientConnector(undefined);
    setDisconnectionReason('');
    setShowLoader(false);
  };

  const onConfirmDisconnectClientConnectorClick = async () => {
    if (!selectedClientConnector) return;

    setShowLoader(true);
    setShowDisconnectionConfirmationModal(false);

    await disconnectClientConnector(
      selectedClientConnector,
      disconnectionReason
    );

    setSelectedClientConnector(undefined);
    setDisconnectionReason('');
    setShowLoader(false);
  };

  const onConfirmDeletionClientConnectorClick = async () => {
    if (!selectedClientConnector) return;

    setShowLoader(true);
    setShowDeletionConfirmationModal(false);

    await deleteClientConnector(selectedClientConnector);

    setSelectedClientConnector(undefined);
    setShowLoader(false);
  };

  const onEditClientConnectorClick = async (
    clientConnector: B2CClientConnector
  ) => {
    setSelectedClientConnector(clientConnector);
    setShowLoader(true);
    setShowClientConnectorConfigurationFormModal(true);
  };

  const onClientConnectorConfigurationFormModalCancelClick = () => {
    // if (isNew) {
    //   window.locatiocn.href = '/home';
    //   return;
    // }

    setShowClientConnectorConfigurationFormModal(false);
    setSelectedClientConnector(undefined);
  };

  const onClientConnectorConfigurationFormModalSave = async (
    clientConnector: B2CClientConnector | undefined
  ) => {
    if (clientConnector !== undefined && clientConnectorList !== undefined) {
      let selectedClientConnectorIndex = clientConnectorList?.findIndex(
        (_) => _.Id === clientConnector.Id
      );

      clientConnectorList[selectedClientConnectorIndex] = clientConnector;

      setClientConnectorList(clientConnectorList);

      await refreshLists();
    }

    setShowClientConnectorConfigurationFormModal(false);
    setSelectedClientConnector(undefined);
    setShowLoader(false);
  };

  const onAddClientConnectorClick = () => {
    setShowLoader(true);

    if (connectorTypeDefinition.RequiresAuthorisation) {
      window.location.replace(
        `${process.env.REACT_APP_B2C_API_URL}/api/authorise/${connectorType}?state=${store?.organisationSelected?.organisationId}`
      );
    } else {
      setSelectedClientConnector({
        SourceConnectorId: connectorTypeDefinition.Id,
      } as B2CClientConnector);
      setShowClientConnectorConfigurationFormModal(true);
      setShowLoader(false);
    }
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      searchParams.set('search', event.target.value.toLowerCase());
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);
  };

  const onSearchChangeDebounce = debounce(onSearchChange, 300);

  const onProcessClientConnectorClick = async (
    clientConnector: B2CClientConnector
  ) => {
    try {
      setShowLoader(true);
      await Business2CloudApiService.Instance.processClientConnectorAsync(
        store?.organisationSelected?.organisationId,
        clientConnector.Id
      );
    } finally {
      setShowLoader(false);
      refreshLists();
    }
  };

  if (!clientConnectorList) {
    return (
      <div className="row justify-content-center">
        <div className="p-5 text-center">
          <svg
            className="spinner primary"
            width="50px"
            height="50px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <>
      {error && (
        <div className={`error-message sticky ${hideError ? 'hide' : ''}`}>
          <span>
            <div dangerouslySetInnerHTML={{ __html: error }}></div>
            <i className="material-symbols-rounded" onClick={onErrorHideClick}>
              cancel
            </i>
          </span>
        </div>
      )}

      {/* Add new notes section for Xero - moved above buttons */}
      {connectorType?.toLowerCase() === 'xero' && (
        <div
          className="connector-notes mb-2"
          style={{ backgroundColor: 'white' }}
        >
          <div className="row">
            <div className="col-md-7">
              <h5 className="notes-title">
                Important Notes for Xero Connector
              </h5>
              <div>
                <p style={{ fontWeight: 'bold' }}>Getting started:</p>
                <ul>
                  <li>
                    1. If you are going to self host your data in Azure SQL then
                    click the pencil and configure your connector.
                  </li>
                  <li>
                    2. If you want Larasoft to host your data, go ahead and move
                    to the refresh tab and click refresh. email us at{' '}
                    <a href="mailto:hello@larasoft.global?subject=Larasoft%20Xero%20Connector%20setup%20assistance">
                      hello@larasoft.global
                    </a>{' '}
                    for access to that data.
                  </li>
                  <li>
                    3. If you would like the use a quick start Power BI template
                    we have created you can install the AppSource App by
                    clicking this link{' '}
                    <a
                      href="https://larasoft.global/power-bi-xero-connector/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Xero Connector + Power BI
                    </a>
                  </li>
                  <li>
                    4. For any other assistance email us at{' '}
                    <a href="mailto:hello@larasoft.global?subject=Larasoft%20Xero%20Connector%other%20enquiry">
                      hello@larasoft.global
                    </a>{' '}
                    and we would be happy to help.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="page-buttons">
        {currentRole.indexOf('admin') >= 0 && (
          <div className="d-flex flex-row gap-2">
            {clientConnectorList?.length > 0 &&
              connectorType?.toLowerCase() !== 'xero' && (
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={onDisconnectAllClientConnectorClick}
                  disabled={
                    clientConnectorList.filter(
                      (_) => _.Status !== 'DISCONNECTED'
                    ).length === 0
                  }
                >
                  <span>Disconnect all</span>
                </button>
              )}
            <button
              className="btn btn-sm btn-primary"
              onClick={onAddClientConnectorClick}
            >
              Connect/ Reconnect
            </button>
          </div>
        )}
        <div className="ms-auto">
          <input
            type="text"
            className="form-control page-search"
            placeholder="Search"
            onChange={onSearchChangeDebounce}
          />
        </div>
      </div>

      {!clientConnectorList.filter(
        (connector) =>
          connector.Status !== 'DELETED' || currentRole === 'superadmin'
      ).length && (
        <div className="--empty">
          <div>Nothing much to do here.</div>
          <small className="light-text">Why not try adding a connector?</small>
        </div>
      )}
      <div className="grid" ref={containerRef}>
        {clientConnectorList
          ?.filter(
            (connector) =>
              connector.Status !== 'DELETED' || currentRole === 'superadmin'
          )
          .map((clientConnector, index) => {
            return (
              <Business2CloudConnectorConfigureCard
                userList={userList}
                clientConnector={clientConnector}
                key={index}
                onAssignUserClick={onAssignUserClick}
                onEditClientConnectorClick={onEditClientConnectorClick}
                onDisconnectClientConnectorClick={
                  onDisconnectClientConnectorClick
                }
                onDeleteClientConnectorClick={onDeleteClientConnectorClick}
                onProcessClientConnectorClick={onProcessClientConnectorClick}
              />
            );
          })}
      </div>
      {showDisconnectionConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Disconnect</h5>
                      <span>
                        Are you sure you want to disconnect{' '}
                        <b>{selectedClientConnector?.Name}</b>?
                      </span>
                    </div>
                  </FormSection>
                  {/* <FormSection>
                      <div className="form-group mb-3">
                        <label htmlFor="disconnectionReason">Reason for disconnection</label>
                        <input
                          type="text"
                          className="form-control"
                          id="disconnectionReason"
                          placeholder="Enter reason for disconnection"
                          value={disconnectionReason}
                          onChange={(e) => setDisconnectionReason(e.target.value)}
                        />
                      </div>
                    </FormSection> */}
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() =>
                          setShowDisconnectionConfirmationModal(false)
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={onConfirmDisconnectClientConnectorClick}
                      >
                        Disconnect
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {showDeletionConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Delete</h5>
                      <span>
                        Are you sure you want to delete{' '}
                        <b>{selectedClientConnector?.Name}</b>?
                      </span>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => setShowDeletionConfirmationModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={onConfirmDeletionClientConnectorClick}
                      >
                        Delete
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {showDisconnectAllConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Disconnect</h5>
                      <span>
                        Are you sure you want to disconnect <b>all</b>{' '}
                        {connectorType} connectors?
                      </span>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="form-group mb-3">
                      <label htmlFor="batchDisconnectionReason">
                        Reason for disconnection
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="batchDisconnectionReason"
                        placeholder="Enter reason for disconnecting all connectors"
                        value={disconnectionReason}
                        onChange={(e) => setDisconnectionReason(e.target.value)}
                      />
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() =>
                          setShowDisconnectAllConfirmationModal(false)
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={onConfirmDisconnectAllClientConnectorClick}
                      >
                        Disconnect
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {assignUser && (
        <div className="modal modal-lg">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title">
                    {selectedClientConnector?.Name}
                  </h5>
                  <span className="light-text">Manage users</span>
                </div>
                <input
                  type="text"
                  className="modal-header-search form-control"
                  placeholder="search"
                  value={userSearchString}
                  onChange={onUserSearchChange}
                />
              </div>
              <div className="modal-body modal-body--no-padding">
                <table className="table table--no-head-border table--no-foot-border">
                  <colgroup>
                    <col style={{ width: '80px' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {userList
                      ?.filter(
                        (user) =>
                          !userSearchString ||
                          user.FirstName.toLowerCase().indexOf(
                            userSearchString
                          ) >= 0 ||
                          user.LastName.toLowerCase().indexOf(
                            userSearchString
                          ) >= 0 ||
                          user.EmailAddress.toLowerCase().indexOf(
                            userSearchString
                          ) >= 0
                      )
                      .map((user, index) => {
                        let checked = !!selectedUserList?.find(
                          (_) => _.Id === user.Id
                        );
                        let baseColor = colors.randomFromString(
                          user.FirstName + user.LastName
                        );
                        let backgroundColor = colors.blend(
                          baseColor,
                          new colors([255, 255, 255]),
                          'hex',
                          0.9
                        )._hex;

                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center', width: 30 }}>
                              <input
                                autoComplete="off"
                                type="checkbox"
                                className="form-check-input"
                                id={user.Id.toString()}
                                checked={!!checked}
                                onChange={(event) =>
                                  onSelectUserChanged(event, user)
                                }
                              />
                            </td>
                            <td style={{ paddingLeft: 0 }}>
                              <div className="d-flex flex-row gap-3 align-items-center">
                                <span className="status-label-stack">
                                  <span
                                    className="status-label"
                                    key={index}
                                    style={{ backgroundColor }}
                                    title={`${user.FirstName} ${user.LastName}`}
                                  >
                                    {user.FirstName.charAt(0)}
                                    {user.LastName?.charAt(0)}
                                  </span>
                                </span>
                                <div>
                                  <div
                                    style={{
                                      maxWidth: 400,
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      marginBottom: -5,
                                    }}
                                  >
                                    <b>
                                      {user.FirstName} {user.LastName}
                                    </b>
                                  </div>
                                  <small className="light-text d-inline-block">
                                    <div>
                                      <div>
                                        <b>{user.EmailAddress}</b>
                                      </div>
                                    </div>
                                  </small>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={onBackClick}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={onAssignUserSaveClick}
                  disabled={!showSaveButton}
                >
                  Save
                </button>
              </div>
              {showLoader && (
                <div className="card-loader">
                  <svg
                    className="spinner primary"
                    width="50px"
                    height="50px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle"
                      fill="none"
                      strokeWidth="6"
                      strokeLinecap="round"
                      cx="33"
                      cy="33"
                      r="30"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </div>
      )}
      {showClientConnectorConfigurationFormModal && (
        <ClientConnectorConfigurationFormModal
          clientConnector={selectedClientConnector}
          setShowContainerLoader={setShowLoader}
          setClientConnector={setSelectedClientConnector}
          onSave={onClientConnectorConfigurationFormModalSave}
          onCancel={onClientConnectorConfigurationFormModalCancelClick}
        />
      )}
      {showLoader && (
        <div className="dashboard__content__loader">
          <svg
            className="spinner primary"
            width="50px"
            height="50px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default withRouter(Business2CloudConnectorConfiguration);
