import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { Store } from '../store/Store';
import { orderBy, take } from 'lodash';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const colors = require('ac-colors');

const SubAreaCard = ({ subarea, onEditClick, onDeleteClick }: { subarea: any; onEditClick: (area: any) => void; onDeleteClick: (area: any) => void }) => {
  const [showLoader] = useState(false);
  const [areaList, setAreaList] = useState(orderBy(subarea.Areas, _ => _.Name))

  const store = useContext(Store).store;

  subarea.Status = subarea.Status || { Name: 'active' };

  useEffect(() => setAreaList(orderBy(subarea.Areas, _ => _.Name)), [subarea.Areas]);

  return (
    <div className="card card-sm grid-item">
      <div className="card-header align-items-end">
        <div className="d-flex flex-column gap-3 me-auto">
          <span className="text-uppercase fw-bold">
            {(subarea.Status.Name.toLowerCase() === 'active' && (
              <span className="p-1 px-2 fs-11 text-success bg-success bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                active
              </span>
            )) ||
              (subarea.Status.Name.toLowerCase() === 'archived' && (
                <span className="p-1 px-2 fs-11 text-dark bg-dark bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                  archived
                </span>
              )) ||
              (subarea.Status.Name.toLowerCase() === 'locked' && (
                <span className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                  locked
                </span>
              ))}
          </span>
          <span className="d-flex flex-column">
            <small className="fw-bold darker-text fs-11">{subarea.Id}</small>
            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>{subarea.Name}</span>
          </span>
        </div>
      </div>
      <div className="card-body">
        <>
          {areaList && (
            <div className="status-label-stack">
              {take(orderBy(areaList, _ => _.Name), 10).map((area: any, index: number) => {
                let baseColor = colors.randomFromString(area.Name);
                let backgroundColor = colors.blend(baseColor, new colors([255, 255, 255]), 'hex', 0.9)._hex;

                return (
                  <span className="status-label" key={index} style={{ backgroundColor }} title={`${area.Name}`}>
                    {area.Name.charAt(0)}
                  </span>
                );
              })}
              {areaList.length > 10 && <span className="status-label additional">+ {areaList.length - 10}</span>}
            </div>
          )}
        </>
      </div>
      <div className="card-footer gap-2 mt-auto d-flex flex-row">
        <button className="btn btn-sm btn-round btn-secondary" title="Edit" onClick={() => onEditClick(subarea)}>
          <span className="material-symbols-rounded">edit</span>
        </button>
        {store.user.roleLevel <= 1 && (
          <button className="btn btn-sm btn-round btn-secondary btn-danger" title="Delete" onClick={() => onDeleteClick(subarea)}>
            <span className="material-symbols-rounded">delete</span>
          </button>
        )}
      </div>
      {showLoader && (
        <div className="card-loader">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default SubAreaCard;
