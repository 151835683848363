import { ChangeEvent, Fragment, useContext, useState } from 'react';
import { Store } from "../../../store/Store";

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';
import toast from "react-hot-toast";
import Spinner from "../../Spinner";
import Form from "./../../../components/Form";

const PowerOfficeBusiness2CloudConnectorConfiguration = ({ configuration }: any) => {
  const store = useContext(Store).store;

  const [isLoading, setIsLoading] = useState(false);
  const [organisationNumber, setOrganisationNumber] = useState<string>();

  const onConnectClick = () => {
    setIsLoading(true);

    var request = fetch(`${process.env.REACT_APP_B2C_API_URL}/api/authorise/poweroffice?state=${store?.organisationSelected?.organisationId}`, {
      method: 'post',
      body: JSON.stringify({ organisationNumber: organisationNumber }),
      headers: { 'Content-Type': 'application/json' },
    });

    request
      .then(response => response.json().then(responseContent => {
        if (responseContent.url) {
          window.location.replace(responseContent.url);
        }
      }))
      .catch(() => {
        toast.error("Something seems to have gone unexpectedly wrong.");
        setIsLoading(false);
      });
  }

  if (!configuration.clientConnector?.Id)
    return <Form>
      <div className="modal modal-sm">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h5 className="modal-title">Power Office</h5>
                <span className="light-text">Connect</span>
              </div>
            </div>
            <div className="modal-body">
              <FormSection>
                <div className="form-section-header">
                  <h5>Integration Settings</h5>
                  <span>The Power Office Organisation Number is required to add a connector.</span>
                </div>
                <div className="tw-flex tw-items-end tw-content-end tw-relative">
                  <FormInput
                    inputClassName="!tw-pe-40"
                    id="poweroffice.organisationNumber"
                    type="text"
                    name="Organisation Number"
                    label="Organisation Number"
                    value={organisationNumber}
                    placeholder="Organisation Number"
                    required
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setOrganisationNumber(event.currentTarget.value)}
                  />
                </div>
              </FormSection>
            </div>
            <div className="modal-footer">
              <button className="btn btn-sm btn-outline-dark" onClick={configuration.onCancel}>
                Cancel
              </button>
              <button disabled={isLoading} className="btn btn-primary" title="Connect" onClick={onConnectClick} type="button">
                {isLoading && <Spinner />}
                {!isLoading && <Fragment><span className="material-symbols-rounded">link</span> Connect</Fragment>}
              </button>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </div>
    </Form>
};

export default PowerOfficeBusiness2CloudConnectorConfiguration;
