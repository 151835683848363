import { useContext, useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { useInterval } from 'usehooks-ts';
import { useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import B2CClientConnector from '../interfaces/B2CClientConnector';
import Business2CloudApiService from '../services/business2CloudApiService';
import Business2CloudConnectorManageCard from './Business2CloudConnectorManageCard';

//Manage and manage card are the same as the Refresh tab 
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const Business2CloudConnectorManagement = (props: any) => {
  const { connectorType } = useParams();

  const storeContext = useContext(Store);
  const currentRole = jwt<any>(storeContext?.store?.jwt)?.Role?.toLowerCase() ?? '';

  const [searchParams, setSearchParams] = useSearchParams();
  const [clientConnectorList, setClientConnectorList] = useState<B2CClientConnector[]>();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
    refreshhClientConnectorList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeContext.store?.organisationSelected?.organisationId, connectorType]);

  useInterval(() => refreshhClientConnectorList(), 10000);

  const refreshhClientConnectorList = async () => {
    let clientConnectors = await Business2CloudApiService.Instance.getClientConnectorsByTypeAsync(
      storeContext.store?.organisationSelected?.organisationId,
      connectorType as string
    );

    setClientConnectorList(clientConnectors);

    setShowLoader(false);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      searchParams.set('search', event.target.value.toLowerCase());
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);
  };

  const onProcessClientConnectorClick = async (
    event: MouseEvent<HTMLButtonElement>,
    clientConnector: B2CClientConnector,
    fullTransactionSync: boolean = false
  ) => {
    if (event.currentTarget) event.currentTarget.disabled = true;

    var clientConnectors = [...(clientConnectorList as any[])];
    var clientConnectorIndex = clientConnectors.findIndex((_) => _.Id === clientConnector.Id);

    // First update UI to show process started
    clientConnectors[clientConnectorIndex].Log = {
      ManuallyTriggeredAtUtc: new Date(),
      CompletedAtUtc: null,
      Error: null
    };

    setClientConnectorList([...clientConnectors]);

    // Trigger the refresh process with status message
    await fetch(`${process.env.REACT_APP_B2C_API_URL}/api/clients/${clientConnector.Client.Key}/${clientConnector.Key}/process`, {
      method: 'post',
      body: JSON.stringify({
        fullTransactionSync,
        statusMessage: `Successful refresh at ${new Date().toLocaleString()}`
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Wait a short time for the process to complete
    await new Promise(resolve => setTimeout(resolve, 2000));

    // Force a refresh of the list to get updated status
    await refreshhClientConnectorList();

    if (event.currentTarget) event.currentTarget.disabled = false;
  };

  const onSearchChangeDebounce = debounce(onSearchChange, 300);

  if (!clientConnectorList) {
    return (
      <div className='row justify-content-center'>
        <div className='p-5 text-center'>
          <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
            <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <p>To re-connect any of these connectors navigate to the <Link to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE.replace(':connectorType', connectorType as string)}><b>Manage tab</b></Link>.</p> */}
      <div className='page-buttons'>
        <input type='text' className='form-control page-search' placeholder='search' onChange={onSearchChangeDebounce} />
      </div>
      {!clientConnectorList.filter(connector => 
        connector.Status !== 'DELETED' || currentRole === 'superadmin'
      ).length && (
        <div className='--empty'>
          <div>Nothing much to do here.</div>
          {currentRole?.toLowerCase().indexOf('admin') >= 0 ? (
            <small className='light-text'>Why not try adding a connector?</small>
          ) : (
            <small className='light-text'>Why not ask your administrator for permission to see your organisation's connectors?</small>
          )}
        </div>
      )}
      <div className='grid'>
        {clientConnectorList
          ?.filter(connector => 
            connector.Status !== 'DELETED' || currentRole === 'superadmin'
          )
          .map((clientConnector, index) => {
            return (
              <Business2CloudConnectorManageCard
                clientConnector={clientConnector}
                key={index}
                onProcessClientConnectorClick={onProcessClientConnectorClick}
              />
            );
          })}
      </div>
      {showLoader && (
        <div className='dashboard__content__loader'>
          <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
            <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
          </svg>
        </div>
      )}
    </>
  );
};

export default withRouter(Business2CloudConnectorManagement);
