import { ApiService } from '../services/apiService';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ChangeEvent, createRef, useContext, useEffect, useState } from 'react';

import FormSection from './FormSection';
import FormInput from './FormInput';
import Form from './Form';

const TableSync = (props: any) => {
  const store = useContext(Store).store;
  const formRef = createRef<any>();

  const [credentials, setCredentials] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [synchronising, setSynchronising] = useState(false);
  const [error, setError] = useState('');
  const [errorTop, setErrorTop] = useState(0);
  const [errorSticky, setErrorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);

  const onScroll = () =>
    formRef?.current &&
    setErrorTop(formRef.current.getBoundingClientRect().top);

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  useEffect(() => {
    getDatabaseCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.organisationSelected?.organisationId]);

  const getDatabaseCredentials = async () => {
    const credentials = await ApiService.getInstance().getDatabaseCredentials(
      store?.organisationSelected?.organisationId
    );

    setCredentials(credentials);
  };

  const onCredentialsChange = (
    event: ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    setCredentials({
      ...credentials,
      [property]: event.currentTarget.value,
    });
  };

  const saveCredentials = async () => {
    setLoading(true);

    try {
      await ApiService.getInstance().setDatabaseCredentials(
        store.organisationSelected.organisationId,
        credentials.host,
        credentials.database,
        credentials.schema,
        credentials.password,
        credentials.user
      );
    } catch (error: any) {
      setError(
        (error?.message as string) || error || 'An unkown error has occured.'
      );
    }

    setLoading(false);
  };

  const synchroniseDatabase = async () => {
    setLoading(true);
    setSynchronising(true);

    try {
      await ApiService.getInstance().tableSyncByOrganisationId(
        store.organisationSelected?.organisationId
      );
    } catch (error: any) {
      setError(
        (error?.message as string) || error || 'An unkown error has occured.'
      );
    }

    setLoading(false);
    setSynchronising(false);
  };

  return (
    <>
      <div className="page-sub-heading">
        <h4>Database Credentials</h4>
      </div>
      <div className="col-12 col-sm-10 col-lg-10 col-xl-6">
        <div className="mb-4 text-dark fs-8">
          These credentials are for the entire organisation and not per user.
          These SQL credentials will be used to write data to your database
        </div>
        <div className="card card-sm">
          <div className="card-body" ref={formRef}>
            {error && (
              <div
                className={`error-message ${hideError ? 'hide' : ''} ${
                  errorSticky ? 'sticky' : ''
                }`}
              >
                <span>
                  <div dangerouslySetInnerHTML={{ __html: error }}></div>
                  <i
                    className="material-symbols-rounded"
                    onClick={onErrorHideClick}
                  >
                    cancel
                  </i>
                </span>
              </div>
            )}
            <Form onSubmit={() => {}}>
              <FormSection>
                <FormInput
                  id="host"
                  placeholder="host"
                  label="Host"
                  value={credentials.host}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onCredentialsChange(event, 'host')
                  }
                />
                <FormInput
                  id="database"
                  placeholder="database"
                  label="Database"
                  value={credentials.database}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onCredentialsChange(event, 'database')
                  }
                />
                <FormInput
                  id="schema"
                  placeholder="schema"
                  label="Schema"
                  value={credentials.schema}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onCredentialsChange(event, 'schema')
                  }
                />
                <FormInput
                  id="user"
                  placeholder="user"
                  label="Username"
                  value={credentials.user}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onCredentialsChange(event, 'user')
                  }
                />
                <FormInput
                  type="password"
                  id="password"
                  placeholder="password"
                  label="Password"
                  value={credentials.password}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onCredentialsChange(event, 'password')
                  }
                />
                <div className="flex justify-content-end gap-2 mt-2">
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={synchroniseDatabase}
                  >
                    Synchronise
                  </button>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={saveCredentials}
                  >
                    Save
                  </button>
                </div>
              </FormSection>
            </Form>
            {loading && (
              <div className="card-loader">
                {synchronising && <span>Synchronising</span>}
                <svg
                  className="spinner primary"
                  width="50px"
                  height="50px"
                  viewBox="0 0 66 66"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="circle"
                    fill="none"
                    strokeWidth="6"
                    strokeLinecap="round"
                    cx="33"
                    cy="33"
                    r="30"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        <p style={{ marginBottom: '40px' }}> </p>
        <div>
          <h4>Step 1 | Download our manifest.xml file</h4>
          <p style={{ marginBottom: '40px' }}>
            Download this manifest file before proceeding to step 2:{' '}
            <a href="/ExcelConnectorManifest.xml" download>
              ExcelConnectorManifest.xml
            </a>
          </p>
        </div>
        <div>
          <h4>
            Step 2 | Add the Excel Connector to your Microsoft 365 environment
          </h4>
          <p style={{ marginBottom: '20px' }}>
            This video shows you how to add our Excel Connector to your
            Microsoft 365 environment.
          </p>
        </div>
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: 0,
            overflow: 'hidden',
            maxWidth: '100%',
            background: '#000',
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/WaaNdvmlbuU"
            title="AdminPortal"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
          />
        </div>
        <div>
          <h4>Step 3 | Add the Excel Connector to your Microsoft Excel 365</h4>
          <p style={{ marginBottom: '20px' }}>
            This video shows you how to add our Excel Connector to your
            Microsoft 365 environment.
          </p>
        </div>
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: 0,
            overflow: 'hidden',
            maxWidth: '100%',
            background: '#000',
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/3Q7ClQy-Xnw"
            title="Microsoft365Excel"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(TableSync);
